var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"cols content-header"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$tf('cases')))]),_c('h3',{staticClass:"header-detail"})]),_c('div',{staticClass:"flex-cell--static content-header-buttons"},[_c('el-button',{staticClass:"header-button",attrs:{"name":"create-btn","circle":"","icon":"el-icon-plus","size":"small","disabled":_vm.$hasNoPermission(['ffsecurity_incidents.add_case'])},on:{"click":function($event){return _vm.$router.push({ path: "/cases/create/" })}}})],1)]),_c('el-row',{staticClass:"cols--center mar-v--1"},[_c('div',{staticClass:"flex-cell--static"},[_c('confirm-button',{attrs:{"target":_vm.target,"info":"none","disabled":!(_vm.selection && _vm.selection.length) || _vm.$hasNoPermission('ffsecurity_incidents.delete_case')},on:{"confirm":_vm.deleteSelection}})],1),_c('div',{staticClass:"cell"}),_c('div',{staticClass:"table-pagination pull-right mar-left--1",attrs:{"name":"table-pagination"}},[_c('table-navigation',{attrs:{"state":_vm.state}})],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.state.loading),expression:"state.loading"}],staticClass:"cols flex-wrap cases"},[_c('el-table',{staticClass:"cases-table",attrs:{"name":"cases-table","stripe":"","default-expand-all":true,"size":_vm.$vars.sizes.table || _vm.$vars.sizes.common,"data":_vm.state.items,"row-key":"id"},on:{"selection-change":_vm.selectionChange,"row-click":_vm.rowClick}},[(_vm.hasLooksLikeFilter)?_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.looks_like)?[_c('div',{staticClass:"cols"},[_c('div',{staticClass:"flex-cell--static"},[_c('object-thumbnail',{attrs:{"url":row.looks_like.thumbnail || '',"frame":row.looks_like.source_photo || ''},on:{"preview":function (v) { return _vm.showImage(row.looks_like.source_photo, _vm.getBox(row.looks_like)); }}})],1),_c('div',[_c('div',[_c('router-link',{attrs:{"to":{ path: _vm.getCaseFacePath(row) }}},[_vm._v("Id: "+_vm._s(row.looks_like.id))])],1),_c('div',[_vm._v(_vm._s(_vm.$tf('video_archive_id'))+": "+_vm._s(row.looks_like.video_archive))]),_c('div',[_vm._v(_vm._s(_vm.$tf('created'))+": "+_vm._s(_vm._f("formatDateTime")(_vm._f("parseDate")(row.looks_like.created_date))))])])])]:undefined}}],null,true)}):_vm._e(),_c('el-table-column',{attrs:{"type":"selection"}}),_c('el-table-column',{attrs:{"prop":"id","label":_vm.$tf('id'),"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"name":"item-btn","to":{ path: _vm.getItemLink(row) }}},[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"prop":"claim_id","label":_vm.$tfo('id_ru | kusp'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"name":"item-btn","to":{ path: _vm.getItemLink(row) }}},[_vm._v(_vm._s(row.claim_id))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('name'),"prop":"name","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{attrs:{"name":"item-btn","to":{ path: _vm.getItemLink(row) }}},[_c('span',[_vm._v(_vm._s(row.name))])])],1),_c('div',{staticClass:"text-tetriary"},[_vm._v(_vm._s(row.comment))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('count | faces,,1'),"width":"140","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.face_count)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"active","label":_vm.$tf('active,,0'),"width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"disabled":row.id < 0},on:{"change":function (v) { return _vm.activeChange(v, row); }},model:{value:(row.active),callback:function ($$v) {_vm.$set(row, "active", $$v)},expression:"row.active"}})]}}])}),_c('el-table-column',{attrs:{"prop":"claim_creation_date","label":_vm.$tfo('kusp_date'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("isoStringToDate")(row.claim_creation_date)))),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatTimeHHMM")(_vm._f("isoStringToDate")(row.claim_creation_date)))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"incident_date","label":_vm.$tfo('incident_date'),"width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("isoStringToDate")(row.incident_date)))),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatTimeHHMM")(_vm._f("isoStringToDate")(row.incident_date)))+" ")]}}])})],1)],1),_c('el-row',{staticClass:"cols--center"},[_c('div',{staticClass:"cell"}),_c('div',{staticClass:"table-pagination pull-right mar-v--1",attrs:{"name":"table-pagination"}},[_c('table-navigation',{attrs:{"state":_vm.state}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }