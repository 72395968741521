<template>
  <div>
    <div class="cols content-header">
      <div>
        <h2>{{ $tf('cases') }}</h2>
        <h3 class="header-detail"></h3>
      </div>
      <div class="flex-cell--static content-header-buttons">
        <el-button
          name="create-btn"
          class="header-button"
          circle
          icon="el-icon-plus"
          size="small"
          @click="$router.push({ path: `/cases/create/` })"
          :disabled="$hasNoPermission(['ffsecurity_incidents.add_case'])"
        />
      </div>
    </div>

    <el-row class="cols--center mar-v--1">
      <div class="flex-cell--static">
        <confirm-button
          :target="target"
          info="none"
          @confirm="deleteSelection"
          :disabled="!(selection && selection.length) || $hasNoPermission('ffsecurity_incidents.delete_case')"
        >
        </confirm-button>
      </div>
      <div class="cell"></div>
      <div name="table-pagination" class="table-pagination pull-right mar-left--1">
        <table-navigation :state="state"></table-navigation>
      </div>
    </el-row>

    <div v-loading="state.loading" class="cols flex-wrap cases">
      <el-table
        name="cases-table"
        class="cases-table"
        stripe
        :default-expand-all="true"
        :size="$vars.sizes.table || $vars.sizes.common"
        :data="state.items"
        row-key="id"
        @selection-change="selectionChange"
        @row-click="rowClick"
      >
        <el-table-column type="expand" v-if="hasLooksLikeFilter">
          <template slot-scope="{ row }" v-if="row.looks_like">
            <div class="cols">
              <div class="flex-cell--static">
                <object-thumbnail
                  :url="row.looks_like.thumbnail || ''"
                  :frame="row.looks_like.source_photo || ''"
                  @preview="(v) => showImage(row.looks_like.source_photo, getBox(row.looks_like))"
                ></object-thumbnail>
              </div>
              <div>
                <div>
                  <router-link :to="{ path: getCaseFacePath(row) }">Id: {{ row.looks_like.id }}</router-link>
                </div>
                <div>{{ $tf('video_archive_id') }}: {{ row.looks_like.video_archive }}</div>
                <div>{{ $tf('created') }}: {{ row.looks_like.created_date | parseDate | formatDateTime }}</div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column type="selection"></el-table-column>

        <el-table-column prop="id" :label="$tf('id')" width="60">
          <template slot-scope="{ row }">
            <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.id }}</router-link>
          </template>
        </el-table-column>

        <el-table-column prop="claim_id" :label="$tfo('id_ru | kusp')" width="120">
          <template slot-scope="{ row }">
            <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.claim_id }}</router-link>
          </template>
        </el-table-column>

        <el-table-column :label="$tf('name')" prop="name" min-width="200">
          <template slot-scope="{ row }">
            <div @click.stop>
              <router-link name="item-btn" :to="{ path: getItemLink(row) }">
                <span>{{ row.name }}</span>
              </router-link>
            </div>
            <div class="text-tetriary">{{ row.comment }}</div>
          </template>
        </el-table-column>

        <el-table-column :label="$tf('count | faces,,1')" width="140" align="center">
          <template slot-scope="{ row }">
            {{ row.face_count }}
          </template>
        </el-table-column>

        <el-table-column prop="active" :label="$tf('active,,0')" width="120" align="center">
          <template slot-scope="{ row }">
            <el-checkbox v-model="row.active" :disabled="row.id < 0" @change="(v) => activeChange(v, row)"> </el-checkbox>
          </template>
        </el-table-column>

        <el-table-column prop="claim_creation_date" :label="$tfo('kusp_date')" width="120">
          <template slot-scope="{ row }">
            {{ row.claim_creation_date | isoStringToDate | formatDate }}<br />
            {{ row.claim_creation_date | isoStringToDate | formatTimeHHMM }}
          </template>
        </el-table-column>

        <el-table-column prop="incident_date" :label="$tfo('incident_date')" width="160">
          <template slot-scope="{ row }">
            {{ row.incident_date | isoStringToDate | formatDate }}<br />
            {{ row.incident_date | isoStringToDate | formatTimeHHMM }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-row class="cols--center">
      <div class="cell"></div>
      <div name="table-pagination" class="table-pagination pull-right mar-v--1">
        <table-navigation :state="state"></table-navigation>
      </div>
    </el-row>
  </div>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import ConfirmButton from '../confirm/confirm-button';
import qs from 'qs';
import ObjectThumbnail from '@/components/objects/thumbnail.vue';

export default {
  components: {
    TableNavigation,
    ObjectThumbnail,
    ConfirmButton
  },
  name: 'page-cases',
  computed: {
    model() {
      return 'cases';
    },
    state() {
      return this.$store.state.cases;
    },
    stats() {
      return this.$store.state.stats;
    },
    hasLooksLikeFilter() {
      return this.state.filter.current.looks_like;
    },
    target() {
      return this.selection.length > 1 ? 'cases' : 'case';
    }
  },
  mounted() {
    this.applyQueryFilter();
    this.$store.dispatch(this.state.Action.Get);
  },
  beforeDestroy() {},
  data() {
    return {
      selection: []
    };
  },
  methods: {
    applyQueryFilter() {
      const filter = this.$route.params.filter;
      if (filter) {
        const queryFilter = qs.parse(filter);
        if (queryFilter.limit) queryFilter.limit = parseInt(queryFilter.limit, 10);
        this.state.filter.current = Object.assign(this.state.filter.current, queryFilter);
      }
    },
    rowClick(item, cell) {
      const cellName = cell.property;

      if (cellName === 'active') {
        return;
      } else if (cellName === 'face_count') {
        this.$router.push({ path: this.getItemLink(item) }); // todo
      } else {
        this.$router.push({ path: this.getItemLink(item) });
      }
    },
    getItemLink(item) {
      let r = `/cases/${item.id}/faces/filter/` + qs.stringify({ case_in: [item.id], limit: 10 });
      return r;
    },
    getCaseFacePath(item) {
      const face = item.looks_like,
        filter = qs.stringify({ case_in: [item.id], id_gte: [face.id], limit: 100 }),
        r = '/cases/' + encodeURIComponent(item.id) + '/faces/filter/' + filter;
      return r;
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    },
    getBox(v) {
      return {
        x: v.source_coords_left,
        y: v.source_coords_top,
        w: v.source_coords_right - v.source_coords_left,
        h: v.source_coords_bottom - v.source_coords_top
      };
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      });
    },
    selectionChange(v) {
      this.selection = v;
    },
    deleteSelection(v) {
      Promise.all(
        this.selection.map((v) => {
          return this.$store.dispatch(this.state.Action.Delete, { id: v.id });
        })
      )
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$store.dispatch(this.state.Action.Get);
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
          this.$store.dispatch(this.state.Action.Get);
        });
    }
  }
};
</script>

<style lang="stylus">
.cases {
  justify-content: center;
}

.case-table-chart {
  height: 150px;
}

.cases {
  .cases-table {
    .image-thumbnail-correction {
      cursor: default;
    }
  }
}
</style>
